export default {
  plain: {
    fontFamily: "Inconsolata",
    color: "#abb2bf",
    backgroundColor: "#282c34"
  },
  styles: [
    {
      types: ["comment"],
      style: {
        color: "#5c6370",
        fontStyle: "italic"
      }
    },
    {
      types: ["string", "url"],
      style: {
        color: "#98c379"
      }
    },
    {
      types: ["builtin"],
      style: {
        color: "#c678dd"
      }
    },
    {
      types: ["punctuation"],
      style: {
        color: "#abb2bf"
      }
    },
    {
      types: ["function"],
      style: {
        color: "#61aeee"
      }
    },
    {
      types: ["class-name"],
      style: {
        color: "#e6c07b"
      }
    },
    {
      types: ["keyword"],
      style: {
        color: "#c678dd"
      }
    },
    {
      types: ["operator"],
      style: {
        color: "#56b6c2"
      }
    },
    {
      types: ["number"],
      style: {
        color: "#d19a66"
      }
    }
  ]
};
